<template>
  <div class="main-rq">
    <div class="search-rq">
      <el-input
          v-model="input"
          style="width: 100%"
          placeholder="请输入搜索内容"
          :prefix-icon="Search"
          size="large"
          @keyup.enter="getSearch(input)"
      />
    </div>
    <el-scrollbar>
      <div class="scrollbar-flex-content">
        <p v-for="item in GroupData" :key="item" class="scrollbar-demo-item" :class="{ selecteda: Groupid == item.id }" @click="handleGroup(item.id)">
          {{ item.title }}
        </p>
      </div>
    </el-scrollbar>
    <div class="my-help-list">
      <ul class="my-help-for-data-rq">
        <li class="my-help-for-data" v-for="item in artList" :key="item">
          <a :href="'/helpCenterSubpage/id/'+item.id">
            {{ item.title }}
          </a>
        </li>
      </ul>
    </div>
  </div>
  <Footers></Footers>
</template>
<script setup lang="ts">
import Footers from "@/src/components/wap/footer/index.vue";
import {Search} from "@element-plus/icons-vue";
import { postWapHelpGroup,postWapHelpList } from '@/src/api/apiconfig/main'
const GroupData = ref([]);
const Groupid = ref(0);
const artList = ref([]);
const input = ref('');
const fetchData = async () => {
  GroupData.value = await postWapHelpGroup()
  for (const key in GroupData.value) {
    Groupid.value = GroupData.value[key].id; // 假设每个元素都有一个 id 属性
    break;
  }
  fetechListData();
}
const getSearch = () =>{
  const currentUrl = '/helpCenterSearch/value/' + input.value
  window.location.href = currentUrl;
}

const fetechListData = async () => {
  artList.value = await postWapHelpList({id:Groupid.value})
}

const handleGroup = (id:number) =>{
  Groupid.value = id;
  fetechListData();
}
fetchData();

useHead({
  title:'松鼠快图_帮助中心',
  meta:[
    {hid:'keywords',name:'keywords','content':'松鼠快图,帮助中心'},
    {hid:'description',name:'description','content':'松鼠快图帮助中心，提供使用过程中的帮助内容。'},
  ]
})

</script>
<style scoped>
.main-rq{
  padding:10px 5px;
}
.search-rq{
  display: flex;
  justify-content: center;
}

.scrollbar-flex-content {
  display: flex;
}
.scrollbar-demo-item {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin: 10px;
  text-align: center;
  border-radius: 4px;
  color: #333;
  position: relative;
}
.my-help-for-data{
  list-style-type: disc;
  margin-bottom: 20px;
}

.selecteda::after{
  content: ' ';
  display: block;
  width: 20px;
  height: 3px;
  background-color: aqua;
  position: absolute;
  left: 50%; /* 将左边距设置为50% */
  bottom: 0;
  border-radius: 2px;
  transform: translateX(-50%); /* 向左移动自身宽度的一半以居中 */
}
:deep(.el-scrollbar__bar){
  display: none;
}

</style>