<!--帮助中心页面-->
<template>
  <div>
    <PanelHeader class="head"></PanelHeader>
  </div>
  <div>
    <div class="box">
      <h2>请问需要什么帮助?</h2>
      <div v-loading.fullscreen.lock="fullscreenLoading" class="box_input">
        <el-input @keydown.enter="handleEnter(inputData)" style="width: 560px;height: 40px" placeholder="请输入搜索内容"
                  v-model="inputData">
          <template #suffix>
            <el-icon style="cursor: pointer" class="el-input__icon">
              <component :is="Search" @click="handleEnter(inputData)"/>
            </el-icon>
          </template>
        </el-input>
      </div>
      <div class="box_content">
        <div v-for="(item,index) in listData">
          <div @click="getContentTitle(index)" class="content_title">
            <i v-if="contentTitleShow===index" class="iconfont icon-xiajiantou2">
            </i> <i v-else class="iconfont icon-shangjiantou1"></i> {{ item.title }}
          </div>
          <div class="content_row">
            <div v-if="index!==contentTitleShow" class="content_line" v-for="(item2,index2) in item.children">
              <div class="content_line_box">
                <span class="content_line_title"> <i class="iconfont icon-wodesheji3"></i>{{ item2.title }}</span>
                <div v-for="(item3,index3) in item2.children">
                  <li v-if="index3<3"><a class="custom-link" :style="{color:item3.id===liSelectId?'#2254f4':''}"
                                         @click="liSelectId=item3.id" target="_blank"
                                         :href="'/helpCenterSubpage/id/'+item3.id">{{ item3.title }}</a></li>
                </div>
                <div class="viewMore">
                  <a v-if="item2.children.length>3" :href="'/helpCenterDetails/id/'+item2.id">查看更多 ></a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script setup>
import PanelHeader from "../../../layout/components/PanelHeader";
import {Search} from '@element-plus/icons-vue'
import {getHelpList} from "../../api/apiconfig/main";

import {ElLoading} from 'element-plus'

useHead({
  title:'松鼠快图_帮助中心',
  meta:[
    {hid:'keywords',name:'keywords','content':'松鼠快图,帮助中心'},
    {hid:'description',name:'description','content':'松鼠快图帮助中心，提供使用过程中的帮助内容。'},
  ]
})


const fullscreenLoading = ref(true)
const inputData = ref()
const liSelectId = ref()
const contentTitleShow = ref(-1)
const getContentTitle = ((index) => {
  if (contentTitleShow.value === index) {
    contentTitleShow.value = -1
  } else {
    contentTitleShow.value = index
  }
})
const listData = ref([])
// 获取列表(接口)
const helpList = (async () => {
  const response = await getHelpList()
  if (response.data) {
    listData.value = response.data
  } else {
    listData.value = []
  }
  fullscreenLoading.value = false
})
await helpList()
//搜索键盘按压事件
const handleEnter = ((data) => {
  if (data) {
    const currentUrl = '/helpCenterSearch/value/' + data
    window.open(currentUrl, '_blank');
  }
})
</script>
<style scoped lang="less">
.box {
  user-select: none;
  background-color: #F6F9FF;
  overflow: auto;
  height: calc(100vh - 71px);

  h2 {
    text-align: center;
  }

  .box_input {
    display: flex;
    justify-content: center;
  }

  .box_content {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: 1360px;
    width: 100%;

    .content_title {
      width: 1360px;
      cursor: pointer;
      font-size: 19px;
      font-weight: 600;
      margin: 20px 10px 0 0;

      i {
        font-size: 12px;
        margin-right: 2px;
      }
    }

    .content_row {
      width: 1360px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 32px;
      margin-bottom: 10px;

      .content_line {
        width: 100%;
        height: 238px;
        margin-top: 20px;
        background: var(--background-color-overlay-regular, #fff);
        border-radius: 12px;

        .content_line_box {
          padding: 24px 32px;

          .content_line_title {
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 20px;

            i {
              margin-right: 10px;
              font-size: 20px;
            }
          }

          div {
            li {
              list-style-type: disc; /* 黑色圆点 */
              margin-bottom: 12px;
            }
          }

          span {
            display: flex;
          }

          .viewMore {
            margin-top: 20px;

            a {
              color: #2254f4;
              cursor: pointer;
              text-decoration: none;
              font-size: 14px;
            }
          }

          .custom-link:hover {
            color: #2254f4;
          }

          .custom-link {
            font-size: 14px;
            text-decoration: none;
            color: #222529;
          }
        }
      }
    }
  }
}
</style>