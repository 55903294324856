<!--模板中心-->
<template>
  <component :is="deviceComponent" />
</template>
<script setup lang="ts">
import helpCenterPc  from "@/src/components/helpCenter/helpCenterPc.vue";
import helpCenterWap from "@/src/components/wap/helpCenter/index.vue";
import { useDevice } from '@/src/utils/useDevice'
const { deviceComponent } = useDevice(helpCenterPc, helpCenterWap)
</script>
<style scoped>
body{

}
</style>
